export const NotificationsSvg = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4479 9.62591C14.9066 13.8817 16.7105 15.1683 16.7105 15.1683H2.5C2.5 15.1683 4.86842 13.4794 4.86842 7.56731C4.86842 6.22368 5.36737 4.93469 6.25553 3.98456C7.14447 3.03444 8.35 2.5 9.60526 2.5C9.87132 2.5 10.1358 2.52375 10.3947 2.57126M10.9711 17.5436C10.8324 17.7838 10.6333 17.9831 10.3935 18.1218C10.1538 18.2604 9.88197 18.3333 9.60526 18.3333C9.32856 18.3333 9.05671 18.2604 8.81699 18.1218C8.57728 17.9831 8.37811 17.7838 8.23947 17.5436M15.1316 7.25061C15.7597 7.25061 16.3621 7.00035 16.8063 6.5549C17.2505 6.10944 17.5 5.50527 17.5 4.8753C17.5 4.24533 17.2505 3.64117 16.8063 3.19571C16.3621 2.75025 15.7597 2.5 15.1316 2.5C14.5034 2.5 13.901 2.75025 13.4569 3.19571C13.0127 3.64117 12.7632 4.24533 12.7632 4.8753C12.7632 5.50527 13.0127 6.10944 13.4569 6.5549C13.901 7.00035 14.5034 7.25061 15.1316 7.25061Z"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
